import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lessons-mistakes-card-toggle"
export default class extends Controller {
  static targets = ["content"];

  toggleText() {
    this.contentTargets.forEach((element) => {
      element.classList.toggle("hidden");
      element.classList.toggle("block");
    });
  }
}
