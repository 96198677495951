import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = [
    "form",
    "searchField",
    "filterDropdownArrow",
    "filtersDropdownContent",
    "pageField",
    "pagination",
    "sortType",
  ];
  static debounces = ["search"];

  connect() {
    useDebounce(this, { wait: 500 });
    this.inputs = this.formTarget.querySelectorAll("input");
    this.inputs.forEach((input) => {
      // discarding adding event for search input for debouncing
      if(input.classList?.contains("no-form-event"))return;
      input.addEventListener("input", this.onInputChange.bind(this))
    });
  }

  paginationTargetConnected() {
    this.paginationLinks = this.element.querySelectorAll("nav.pagination a");
    this.paginationLinks.forEach((input) =>
      input.addEventListener("click", this.onPageNavigation.bind(this)),
    );
  }

  onInputChange() {
    this.pageFieldTarget.value = "1";
    Rails.fire(this.formTarget, "submit");
  }

  onPageNavigation(event) {
    if (this.hasPageFieldTarget) {
      event.preventDefault();
      const href = event.currentTarget.getAttribute("href");
      const pageNum = /page=([0-9]+)/.test(href)
        ? href.match(/page=([0-9]+)/)[1]
        : "1";
      this.pageFieldTarget.value = pageNum;
      Rails.fire(this.formTarget, "submit");
    }
  }

  search(event) {
    this.searchFieldTarget.value = event.target.value;
    this.searchFieldTarget.dispatchEvent(new Event("input"));
  }

  changeSortable(event) {
    console.log(event.target, event.target.innerText);
    this.sortTypeTarget.value = event.target.value;
  }

  clearAll() {
    this.inputs.forEach((input) => {
      if (input.tagName !== "INPUT" || input.type === "hidden") return;

      if (input.type === "text") {
        input.value = "";
        return;
      }
      input.checked = false;
    });
    Rails.fire(this.formTarget, "submit");
  }

  toggleFilter() {
    this.filtersDropdownContentTarget.classList.toggle("hidden");
    this.filterDropdownArrowTarget.classList.toggle("rotate-180");
  }
}
