import { Dropdown } from "tailwindcss-stimulus-components";
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="custom-dropdown"
export default class extends Dropdown {
  static targets = ["arrow"];

  connect() {
    super.connect();
    // useClickOutside(this)
  }

  openValueChanged() {
    super.openValueChanged();
    if (this.hasArrowTarget) {
      this.openValue
        ? this.arrowTarget.classList.add("rotate-180")
        : this.arrowTarget.classList.remove("rotate-180");
    }
  }

  hide() {
    this.openValue = false;
  }

  clickOutside() {
    this.hide();
  }
}
