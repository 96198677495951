// app/javascript/controllers/dialog_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    // Ensure the dialog is initially hidden
    this.hide();
  }

  show() {
    this.containerTarget.classList.remove("hidden");
    this.containerTarget.classList.add("show");
  }

  hide() {
    this.containerTarget.classList.remove("show");
    this.containerTarget.classList.add("hidden");
  }

  toggle() {
    if (this.containerTarget.classList.contains("show")) {
      this.hide();
    } else {
      this.show();
    }
  }

  close() {
    this.hide();
  }
}
